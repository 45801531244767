export default defineNuxtRouteMiddleware(async () => {
  const { accessToolbar } = await useDrupalUser()

  if (!accessToolbar.value) {
    return abortNavigation({
      statusCode: 404,
      statusMessage: 'Page Not Found',
    })
  }
})
